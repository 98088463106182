<template>
  <div class="page2">
    <img class="page2-img" src="../../assets/pages/obj_02.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page2',
}
</script>

<style scoped lang="scss">
.page2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page2-img {
    height: 100%;
  }

}
</style>